import * as React from "react";
import Slider from "react-slick";
import ImageSlide from "./slides/ImageSlide";
import styles from "./Carousel.module.css";
import { ApolloQueryResult } from "@apollo/client";
import { GetPostsForHomepageQuery } from "../../lib/api/types";
import { PostStyles } from "../utils/PostStyles";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel = ({ bannerPosts }: { bannerPosts: ApolloQueryResult<GetPostsForHomepageQuery> }): JSX.Element => {
  if (bannerPosts.data.posts.edges.length === 0) {
    return null;
  }

  if (bannerPosts.data.posts.edges.length === 1) {
    const [slide] = bannerPosts.data.posts.edges;
    return (
      <>
        <PostStyles />
        <ImageSlide
          key={slide.node.id}
          href={slide.node.excerpt?.replace(/(<([^>]+)>)/gi, "")}
          src={slide.node.featuredImage.node.sourceUrl}
          type={slide.node.tags.edges.filter((t) => t.node.name !== "banner").map((t) => t.node.name)}
        >
          {slide.node.content && <div dangerouslySetInnerHTML={{ __html: slide.node.content }} />}
        </ImageSlide>
      </>
    );
  }

  return (
    <>
      <PostStyles />
      <Slider {...settings} className={styles.slick}>
        {bannerPosts.data.posts.edges.map((slide) => (
          <ImageSlide
            key={slide.node.id}
            href={slide.node.excerpt?.replace(/(<([^>]+)>)/gi, "")}
            src={slide.node.featuredImage.node.sourceUrl}
            type={slide.node.tags.edges.filter((t) => t.node.name !== "banner").map((t) => t.node.name)}
          >
            {slide.node.content && <div dangerouslySetInnerHTML={{ __html: slide.node.content }} />}
          </ImageSlide>
        ))}
      </Slider>
    </>
  );
};

export default Carousel;
