import * as React from "react";
import styles from "./ImageSlide.module.scss";
import Link from "next/link";
import Image from "next/legacy/image";

type Props = {
  children?: React.ReactNode;
  src: string;
  href?: string;
  type: string[];
};
const ImageSlide = ({ type, children, src, href }: Props): JSX.Element => (
  <div className={styles.slide}>
    {href ? (
      <Link href={href}>
        <SliderByType src={src} type={type}>
          {children}
        </SliderByType>
      </Link>
    ) : (
      <SliderByType src={src} type={type}>
        {children}
      </SliderByType>
    )}
  </div>
);

const SliderByType = ({
  type,
  children,
  src,
}: {
  src: string;
  type: string[];
  children: React.ReactNode;
}): JSX.Element => {
  if (type.indexOf("left") !== -1) {
    return (
      <div className={styles.image}>
        <div className={styles.children}>
          <div className={styles.left}>
            <div className={styles.leftInner}>
              <div>
                <div className={styles.content}>{children}</div>
                <div className={styles.glasses}>
                  Akiniai <br />
                  <span className={styles.small}>nuo</span> <span className={styles.big}>55</span>{" "}
                  <span className={styles.small}>eur</span>
                  <br />
                  <Link href="/akiniai" className="btn btn-warning btn-lg btn-rounded">
                    Žiūrėti
                  </Link>
                </div>
              </div>
            </div>
            <Image priority alt="Akiniai nuo 55€" src={src} objectFit="cover" layout="fill" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ backgroundImage: `url(${src})` }} className={styles.image}>
      {children}
    </div>
  );
};

ImageSlide.defaultProps = {
  type: [],
};

export default ImageSlide;
