import * as React from "react";
import { GetStaticProps } from "next";
import {
  GetCategoriesForHomepageDocument,
  GetCategoriesForHomepageQuery,
  GetMenusDocument,
  GetMenusQuery,
  GetPostsForHomepageDocument,
  GetPostsForHomepageQuery,
  ProductsForHomepageDocument,
  ProductsForHomepageQuery,
} from "../lib/api/types";
import { ApolloQueryResult } from "@apollo/client";
import { client } from "../lib/api/api";
import { MenuProvider } from "../components/context/AppContext";
import { Home } from "../components/pages/home/Home";
import { CAT_INDEX_PAGE_ID } from "../lib/util/product";

type Props = {
  categories: ApolloQueryResult<GetCategoriesForHomepageQuery>;
  bannerPosts: ApolloQueryResult<GetPostsForHomepageQuery>;
  menus: ApolloQueryResult<GetMenusQuery>;
  products: Record<string, ApolloQueryResult<ProductsForHomepageQuery>>;
};

const Index = ({ menus, ...props }: Props): JSX.Element => (
  <MenuProvider menus={menus.data}>
    <Home {...props} />
  </MenuProvider>
);

export const getStaticProps: GetStaticProps = async () => {
  const categories: ApolloQueryResult<GetCategoriesForHomepageQuery> = await client.query({
    query: GetCategoriesForHomepageDocument,
    variables: { id: CAT_INDEX_PAGE_ID },
  });

  const products: Array<{ title: string; data: ApolloQueryResult<ProductsForHomepageQuery> }> = await Promise.all(
    categories.data.productCategories.edges.map((cat) =>
      client
        .query({
          query: ProductsForHomepageDocument,
          variables: { categoryIn: [cat.node.slug], first: 16 },
        })
        .then((data) => ({ data, title: cat.node.slug })),
    ),
  );

  return {
    props: {
      products: products.reduce((prev, current) => ({ ...prev, [current.title]: current.data }), {}),
      categories,
      bannerPosts: await client.query({
        query: GetPostsForHomepageDocument,
        variables: { tag: "banner" },
      }),
      menus: await client.query({ query: GetMenusDocument }),
    },
    revalidate: 60,
  };
};

export default Index;
