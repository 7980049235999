import * as React from "react";
import Carousel from "../../carousel/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "../../card/Card";
import styles from "./Home.module.scss";
import { Layout } from "../../Layout";
import Slider from "react-slick";
import { ApolloQueryResult } from "@apollo/client";
import {
  GetCategoriesForHomepageQuery,
  GetPostsForHomepageQuery,
  ProductsForHomepageQuery,
} from "../../../lib/api/types";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { categoryLink, searchLinkFromQuery } from "../../../lib/util/links";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FormIcon } from "../../attributes/Form";
import { newSearchQuery } from "../../router/search";
import { Heading } from "../../headings/Heading";
import { Section } from "../../layout/Section";
import glasses from "./glasses.png";
import Image from "next/legacy/image";

type Props = {
  categories: ApolloQueryResult<GetCategoriesForHomepageQuery>;
  bannerPosts: ApolloQueryResult<GetPostsForHomepageQuery>;
  products: Record<string, ApolloQueryResult<ProductsForHomepageQuery>>;
};

const settings = {
  // infinite: true,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 4,
  slidesToScroll: 4,
  dots: false,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

export const Home = ({ bannerPosts, categories, products }: Props): JSX.Element => {
  return (
    <Layout
      meta={{
        title: "apuokyne.lt",
        description:
          "Madingi ir stilingi akiniai už ypatingai patrauklią kainą. Išsirink iš nustabiausių mūsų akinių pasiūlymų. Įsigyk dabar!",
      }}
    >
      <Carousel bannerPosts={bannerPosts} />
      {categories.data.productCategories.edges.map((cat) => (
        <React.Fragment key={cat.node.id}>
          <Heading link={categoryLink(cat.node)}>
            {cat.node.name} <FontAwesomeIcon icon={faArrowCircleRight} />
          </Heading>
          <Slider {...settings} className={styles.slick}>
            {products[cat.node.slug]?.data.products.edges
              .slice()
              .reverse()
              .map((p) => (
                <Card key={p.node.id} product={p.node} />
              ))}
          </Slider>
        </React.Fragment>
      ))}
      <Section className={styles.formContainer}>
        <Heading className={styles.form}>Rinktis pagal formą</Heading>
        <Container>
          <Row>
            <Col className={styles.formIcon}>
              <Link href={searchLinkFromQuery(newSearchQuery({ PaForma: ["aviatoriaus"] }))}>
                <FormIcon options={["aviatoriaus"]} />
                <div>Aviatoriaus</div>
              </Link>
            </Col>
            <Col className={styles.formIcon}>
              <Link href={searchLinkFromQuery(newSearchQuery({ PaForma: ["kates-akys"] }))}>
                <FormIcon options={["kates-akys"]} />
                <div>Katės-akys</div>
              </Link>
            </Col>
            <Col className={styles.formIcon}>
              <Link href={searchLinkFromQuery(newSearchQuery({ PaForma: ["kvadratiniai"] }))}>
                <FormIcon options={["kvadratiniai"]} />
                <div>Kvadratiniai</div>
              </Link>
            </Col>
            <Col className={styles.formIcon}>
              <Link href={searchLinkFromQuery(newSearchQuery({ PaForma: ["apvalus"] }))}>
                <FormIcon options={["apvalus"]} />
                <div>Apvalūs</div>
              </Link>
            </Col>
            <Col className={styles.formIcon}>
              <Link href={searchLinkFromQuery(newSearchQuery({ PaForma: ["staciakampiai"] }))}>
                <FormIcon options={["staciakampiai"]} />
                <div>Stačiakampiai</div>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section gold fluid className={styles.glasses}>
        <Heading className={styles.h1} link="/kaip-pirkti-akinius">
          Kaip pirkti akinius internetu? <FontAwesomeIcon icon={faArrowCircleRight} />
        </Heading>
        <Row className={styles.howToBuy}>
          <Col md={6} className={styles.text}>
            <Link href="/kaip-pirkti-akinius/akiniu-remelio-forma">
              Forma <div className="text-muted">Kokia rėmelio forma man tinka?</div>
            </Link>
            <Link href="/kaip-pirkti-akinius/akiniu-remelio-dydis">
              Dydis{" "}
              <div className="text-muted">
                Kokį rėmelio dydį man pasirinkti? <br /> Kaip jį skaityti ant savo rėmelio?
              </div>
            </Link>
            <Link href="/kaip-pirkti-akinius/akiniu-lesiu-pasirinkimas">
              Lęšių pasirinkimas <div className="text-muted">Ar man reikia lęšių ploninimo?</div>
            </Link>
            <Link href="/kaip-pirkti-akinius/kaip-pasimatuoti-pd">
              Kaip pasimatuoti PD? <div className="text-muted">Jei jis nenurodytas recepte</div>
            </Link>
            <Link href="/kaip-pirkti-akinius/standartine-ar-premium">
              Standartinė ar Premium?{" "}
              <div className="text-muted">Kuo skiriasi PREMIUM ir STANDART linijos? Kurią rinktis?</div>
            </Link>
          </Col>
          <Col md={6} className={styles.back}>
            <Image
              src={glasses.src}
              alt="Akinių pasirinkimas"
              layout="fill"
              objectFit="cover"
              objectPosition="left bottom"
            />
          </Col>
        </Row>
      </Section>
    </Layout>
  );
};
