import * as React from "react";
import styles from "./Section.module.scss";
import { Container } from "react-bootstrap";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  fluid?: boolean;
  gold?: boolean;
  className?: string;
};

export const Section = ({ children, fluid, gold, className }: Props): JSX.Element => {
  return (
    <Container fluid={fluid} className={classNames(styles.section, { [styles.gold]: gold }, className)}>
      {children}
    </Container>
  );
};
