import * as React from "react";
import styles from "./Heading.module.scss";
import Link from "next/link";
import classNames from "classnames";

type Props = { link?: string; children: React.ReactNode; className?: string };

export const Heading = ({ link, children, className }: Props): JSX.Element => {
  return (
    <div className={classNames(styles.h1, className)}>{link ? <Link href={link}>{children}</Link> : children}</div>
  );
};
