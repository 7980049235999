import * as React from "react";

export const PostStyles = (): JSX.Element => {
  return (
    <>
      <link rel="stylesheet" href="/w.gutenberg-blocks.css" />
      <link rel="stylesheet" href="/w.style.min.css" />
      <link rel="stylesheet" href="/w.icons.css?ver=3.4.0" />
    </>
  );
};
